import React, { memo, forwardRef } from "react";
import { Warning } from "@phosphor-icons/react";
import renderMarkdown from "@/utils/chat/markdown";
import { embedderSettings } from "@/main";
import { v4 } from "uuid";
import createDOMPurify from "dompurify";
import defaultIcon from "@/assets/icon.png";
import { formatDate } from "@/utils/date";

const DOMPurify = createDOMPurify(window);
const HistoricalMessage = forwardRef(
  (
    {
      uuid = v4(),
      message,
      role,
      sources = [],
      error = false,
      errorMsg = null,
      sentAt,
    },
    ref
  ) => {
    const textSize = !!embedderSettings.settings.textSize
      ? `allm-text-[${embedderSettings.settings.textSize}px]`
      : "allm-text-sm";
    if (error) console.error(`BUDDY_SYSTEMS_CHAT_WIDGET_ERROR: ${error}`);

    // Check if this is a disabled embed error
    const isDisabledEmbed = errorMsg && errorMsg.includes("disabled by the administrator");
    const errorTitle = isDisabledEmbed ? "Chat temporarily unavailable" : "Could not respond to message";

    return (
      <div className="py-[5px]">
        {role === "assistant" && (
          <div
            className={`allm-text-[10px] allm-text-gray-400 allm-ml-[54px] allm-mr-6 allm-mb-2 allm-text-left allm-font-sans`}
          >
            {embedderSettings.settings.assistantName ||
              "MendBuddy AI Assistant"}
          </div>
        )}
        <div
          key={uuid}
          ref={ref}
          className={`allm-flex allm-items-start allm-w-full allm-h-fit ${
            role === "user" ? "allm-justify-end" : "allm-justify-start"
          }`}
        >
          {role === "assistant" && (
            <img
              src={embedderSettings.settings.assistantIcon || defaultIcon}
              alt="MendBuddy Icon"
              className="allm-w-9 allm-h-9 allm-flex-shrink-0 allm-ml-2 allm-mt-2"
              id="buddy-systems-icon"
            />
          )}
          <div
            style={{
              wordBreak: "break-word",
              backgroundColor:
                role === "user"
                  ? embedderSettings.USER_STYLES.msgBg
                  : embedderSettings.ASSISTANT_STYLES.msgBg,
            }}
            className={`allm-py-[11px] allm-px-4 allm-flex allm-flex-col allm-font-sans ${
              error
                ? isDisabledEmbed 
                  ? "allm-bg-amber-100 allm-rounded-lg allm-mr-[37px] allm-ml-[9px]"
                  : "allm-bg-red-200 allm-rounded-lg allm-mr-[37px] allm-ml-[9px]"
                : role === "user"
                  ? `${embedderSettings.USER_STYLES.base} allm-buddy-systems-user-message`
                  : `${embedderSettings.ASSISTANT_STYLES.base} allm-buddy-systems-assistant-message`
            } allm-shadow-[0_4px_14px_rgba(0,0,0,0.25)]`}
          >
            <div className="allm-flex">
              {error ? (
                <div className={`allm-p-2 allm-rounded-lg ${isDisabledEmbed ? 'allm-bg-amber-50 allm-text-amber-700' : 'allm-bg-red-50 allm-text-red-500'}`}>
                  <span className={`allm-inline-block `}>
                    <Warning className="allm-h-4 allm-w-4 allm-mb-1 allm-inline-block" />{" "}
                    {errorTitle}
                  </span>
                  <p className={`allm-text-xs allm-font-mono allm-mt-2 allm-border-l-2 ${
                    isDisabledEmbed 
                      ? 'allm-border-amber-500 allm-bg-amber-200' 
                      : 'allm-border-red-500 allm-bg-red-300'
                  } allm-pl-2 allm-p-2 allm-rounded-sm`}>
                    {isDisabledEmbed 
                      ? "This chat is currently unavailable. Please check back later." 
                      : (errorMsg || "Server error")}
                  </p>
                </div>
              ) : (
                <span
                  className={`allm-whitespace-pre-line allm-flex allm-flex-col allm-gap-y-1 ${textSize} allm-leading-[20px]`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(renderMarkdown(message)),
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {sentAt && (
          <div
            className={`allm-font-sans allm-text-[10px] allm-text-gray-400 allm-ml-[54px] allm-mr-6 allm-mt-2 ${role === "user" ? "allm-text-right" : "allm-text-left"}`}
          >
            {formatDate(sentAt)}
          </div>
        )}
      </div>
    );
  }
);

export default memo(HistoricalMessage);
