import { encode as HTMLEncode } from "he";
import markdownIt from "markdown-it";
import { staticHljs as hljs } from "./hljs";
import { v4 } from "uuid";

const markdown = markdownIt({
  html: false,
  typographer: true,
  highlight: function (code, lang) {
    const uuid = v4();
    if (lang && hljs.getLanguage(lang)) {
      try {
        return (
          `<div class="allm-whitespace-pre-line allm-w-full allm-rounded-lg allm-bg-black-900 allm-pb-4 allm-relative allm-font-mono allm-font-normal allm-text-sm allm-text-slate-200 allm-overflow-hidden">
          <div class="allm-w-full allm-flex allm-items-center allm-absolute allm-top-0 allm-left-0 allm-right-0 allm-text-slate-200 allm-bg-stone-800 allm-px-4 allm-py-2 allm-text-xs allm-font-sans">
            <button data-code-snippet data-code="code-${uuid}" class="allm-flex allm-items-center allm-gap-x-2 hover:allm-opacity-80 allm-mr-4">
              <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="allm-h-4 allm-w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
              <p>Copy</p>
            </button>
            <div class="allm-flex allm-gap-2"><code class="allm-text-xs">${lang}</code></div>
          </div>
        <pre class="allm-whitespace-pre-wrap allm-px-4 allm-mt-10">` +
          hljs.highlight(code, { language: lang, ignoreIllegals: true }).value +
          "</pre></div>"
        );
      } catch (__) {}
    }

    return (
      `<div class="allm-whitespace-pre-line allm-w-full allm-rounded-lg allm-bg-black-900 allm-pb-4 allm-relative allm-font-mono allm-font-normal allm-text-sm allm-text-slate-200 allm-overflow-hidden">
        <div class="allm-w-full allm-flex allm-items-center allm-absolute allm-top-0 allm-left-0 allm-right-0 allm-text-slate-200 allm-bg-stone-800 allm-px-4 allm-py-2 allm-text-xs allm-font-sans">
          <button data-code-snippet data-code="code-${uuid}" class="allm-flex allm-items-center allm-gap-x-2 hover:allm-opacity-80 allm-mr-4">
            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="allm-h-4 allm-w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
            <p>Copy</p>
          </button>
          <div class="allm-flex allm-gap-2"><code class="allm-text-xs"></code></div>
        </div>
      <pre class="allm-whitespace-pre-wrap allm-px-4 allm-mt-10">` +
      HTMLEncode(code) +
      "</pre></div>"
    );
  },
})
  // Enable <ol> and <ul> items to not assume an HTML structure so we can keep numbering from responses.
  .disable("list");

export default function renderMarkdown(text = "") {
  return markdown.render(text);
}
